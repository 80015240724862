/* eslint-disable @typescript-eslint/naming-convention */
import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { CategoryInterface } from '../../shared/interfaces/product.interafce';
import { InternetCheckService } from '../../shared/services/internetCheck.service';
import { RequestUnavailableProductComponent } from './request-unavailable-product/request-unavailable-product.component';

@Component({
  selector: 'app-no-search-results',
  templateUrl: './no-search-results.component.html',
  styleUrls: ['./no-search-results.component.scss'],
  standalone: true,
  imports: [NgIf, RequestUnavailableProductComponent, TranslateModule],
})
export class NoSearchResultsComponent implements OnInit {
  searchKey = '';

  isCategoriesIconsLoaded = false;

  isInternetWorking = true;

  categories: CategoryInterface[];

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private route: ActivatedRoute,
    private internetCheckService: InternetCheckService,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.getSearchKey();
  }

  getSearchKey(): void {
    this.internetCheckService.createOnline$().subscribe({
      next: (res) => {
        this.isInternetWorking = res;
      },
    });
    this.route.queryParams.subscribe({
      next: (params: Params) => {
        this.searchKey = params.searchKey || '';
      },
    });
  }

  trackNoSearchResultsEvent(): void {
    const eventBody = {
      'Search Text': this.searchKey ?? '',
      Language: 'ar',
      'Interface Version': 'v2',
    };
    this._logMixpanelEventUseCase.execute({
      eventName: 'No_results_page_load',
      payload: eventBody,
    });
  }

  getCategories(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (tree) => {
        this.categories = tree.root.children.map((child) => {
          const category: CategoryInterface = {
            name: child.value.name.arabicName,
            text: child.value.name.arabicName,
            _id: child.key,
            featured: child.value.featured,
          };
          return category;
        });

        this.isCategoriesIconsLoaded = true;
      },
    });
  }
}
