<section class="container no-search-results-container">
  <section class="additional-search-section">
    <ng-container *ngIf="!isInternetWorking; else noResult">
      <p class="no-results-found-text">
        لا يوجد اتصال بالإنترنت. يرجى التحقق من اتصالك بالإنترنت وحاول مرة أخرى
      </p>
    </ng-container>
    <ng-template #noResult>
      <img
        loading="lazy"
        class="no-results-found-image"
        src="assets/img/no_results_found.svg"
        alt="No results found"
      />
      <p class="no-results-found-text">
        {{ 'NOT_FOUND.NO_RESULTS' | translate }} "{{ searchKey }}"
      </p>
      <p class="no-results-found-help-text">
        {{ 'NOT_FOUND.TRY_AGAIN' | translate }}
      </p>
    </ng-template>
  </section>
  <hr *ngIf="this.categories" />
  <app-request-unavailable-product *ngIf="this.categories" [categories]="categories">
  </app-request-unavailable-product>
</section>
